<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" />
    <table class="table">
      <tbody>
        <tr v-if="showImageInfo">
          <td>
            <strong>{{ $t('image') }}</strong>
          </td>
          <td>
            <RouterLink
              :to="`/project/${annotation.project}/image/${annotation.image}`"
            >
              <ImageName :image="image" />
            </RouterLink>
          </td>
        </tr>

        <template v-if="isPropDisplayed('geometry-info')">
          <tr v-if="annotation.area > 0">
            <td>
              <strong>{{ $t('area') }}</strong>
            </td>
            <td>
              {{ `${annotation.area.toFixed(3)} ${annotation.areaUnit}` }}
            </td>
          </tr>

          <tr v-if="annotation.perimeter > 0">
            <td>
              <strong>{{
                $t(annotation.area > 0 ? 'perimeter' : 'length')
              }}</strong>
            </td>
            <td>
              {{
                `${annotation.perimeter.toFixed(3)} ${annotation.perimeterUnit}`
              }}
            </td>
          </tr>
        </template>

        <tr v-if="isPropDisplayed('description')">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('description') }}
            </h5>
            <CytomineDescription
              :object="annotation"
              :can-edit="canEdit"
              :max-preview-length="300"
            />
          </td>
        </tr>

        <!-- TERMS -->
        <tr v-if="isPropDisplayed('terms') && ontology">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('terms') }}
            </h5>
            <div class="flex gap-4 flex-wrap mb-1">
              <BTag
                v-for="{ term, user } in associatedTerms"
                :key="term.id"
                :title="$t('associated-by', { username: user.fullName })"
                class="bg-gray-1"
              >
                <CytomineTerm :term="term" />
                <button
                  v-if="canEditTerms"
                  :title="$t('delete')"
                  class="delete is-small"
                  @click="removeTerm(term.id)"
                />
              </BTag>
            </div>
            <div
              v-if="canEditTerms"
              v-click-outside="() => (showTermSelector = false)"
              class="relative"
            >
              <BField>
                <BInput
                  v-model="addTermString"
                  :placeholder="$t('add-term')"
                  size="is-small"
                  expanded
                  @focus="showTermSelector = true"
                />
              </BField>

              <div
                v-show="showTermSelector"
                class="
                  ontology-tree-container
                  absolute
                  z-100
                  top-full
                  w-full
                  overflow-auto
                  radius-4
                  bg-white
                "
              >
                <OntologyTree
                  :ontology="ontology"
                  :search-string="addTermString"
                  :selected-nodes="associatedTermsIds"
                  allow-new
                  class="ontology-tree"
                  @newTerm="newTerm"
                  @select="addTerm"
                  @unselect="removeTerm"
                />
              </div>
            </div>
            <em v-else-if="!associatedTerms.length">{{ $t('no-term') }}</em>
          </td>
        </tr>

        <!-- Tags hidden until scoped access per user per project -->
        <!-- <tr v-if="isPropDisplayed('tags')">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('tags') }}
            </h5>
            <CytomineTags :object="annotation" :can-edit="canEdit" />
          </td>
        </tr> -->

        <!-- PROPERTIES -->
        <tr v-if="isPropDisplayed('properties')">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('properties') }}
            </h5>
            <CytomineProperties
              :object="annotation"
              :can-edit="canEdit && isRepresentative && isSuperAdmin"
              @update="$emit('updateProperties')"
            />
          </td>
        </tr>

        <tr v-if="isPropDisplayed('attached-files')">
          <td colspan="2">
            <h5 class="mb-2 weight-6">
              {{ $t('attached-files') }}
            </h5>
            <AttachedFiles :object="annotation" :can-edit="canEdit" />
          </td>
        </tr>

        <template v-if="isPropDisplayed('creation-info')">
          <tr>
            <td>
              <strong>{{ $t('created-by') }}</strong>
            </td>
            <td>
              {{ creator.fullName }}
            </td>
          </tr>
          <tr v-if="!isReview">
            <td>
              <strong>{{ $t('created-on') }}</strong>
            </td>
            <td>{{ Number(annotation.created) | date('ll') }}</td>
          </tr>
          <template v-else>
            <tr>
              <td>
                <strong>{{ $t('reviewed-by') }}</strong>
              </td>
              <td>
                {{ reviewer.fullName }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('reviewed-on') }}</strong>
              </td>
              <td>{{ Number(annotation.created) | date('ll') }}</td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>

    <IdxBtn
      v-if="showImageInfo"
      :to="annotationURL"
      small
      full-width
      color="primary"
      class="mb-1"
    >
      {{ $t('view-in-image') }}
    </IdxBtn>

    <IdxBtn
      v-else
      small
      full-width
      color="primary"
      class="mb-1"
      @click="$emit('centerView')"
    >
      {{ $t('center-view-on-annot') }}
    </IdxBtn>

    <div class="flex gap-4 flex-wrap">
      <IdxBtn
        :href="annotation.url + '?draw=true&complete=true&increaseArea=1.25'"
        target="_blank"
        small
      >
        {{ $t('view-crop') }}
      </IdxBtn>

      <IdxBtn small @click="copyURL">
        {{ $t('copy-url') }}
      </IdxBtn>

      <IdxBtn
        v-if="isPropDisplayed('comments') && comments"
        small
        @click="openCommentsModal"
      >
        {{ $t('comments') }} ({{ comments.length }})
      </IdxBtn>

      <BTooltip
        v-if="!isPreview"
        trigger="hover"
        :label="$t('cut-annotations-description')"
        size="is-small"
        append-to-body="true"
        multilined
      >
        <IdxBtn small @click="confirmCut">
          {{ $t('cut-annotations') }}
        </IdxBtn>
      </BTooltip>

      <IdxBtn v-if="canDelete" small color="danger" @click="confirmDeletion">
        {{ $t('delete') }}
      </IdxBtn>
    </div>
  </div>
</template>

<script>
import {
  Annotation,
  AnnotationTerm,
  AnnotationType,
  AnnotationCommentCollection,
} from 'cytomine-client';
import copyToClipboard from 'copy-to-clipboard';
import noteApi, { reshapeAnnotations } from '../../services/noteApi.js';
import ImageName from '@/components/image/ImageName.vue';
import CytomineDescription from '@/components/description/CytomineDescription.vue';
import CytomineProperties from '@/components/property/CytomineProperties.vue';
import CytomineTags from '@/components/tag/CytomineTags.vue';
import CytomineTerm from '@/components/ontology/CytomineTerm.vue';
import AttachedFiles from '@/components/attached-file/AttachedFiles.vue';
import OntologyTree from '@/components/ontology/OntologyTree.vue';

export default {
  name: 'AnnotationDetails',
  components: {
    ImageName,
    CytomineDescription,
    CytomineTerm,
    OntologyTree,
    CytomineTags,
    CytomineProperties,
    AttachedFiles,
  },
  props: {
    index: { type: String, default: '' },
    annotation: { type: Object, required: true },
    terms: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    images: { type: Array, default: () => [] },
    isPreview: { type: Boolean, default: false },
    showImageInfo: {
      type: Boolean,
      default: true,
    },
    showComments: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addTermString: '',
      showTermSelector: false,
      comments: null,
      revTerms: 0,
      isLoading: false,
    };
  },
  computed: {
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    /** @returns {object} */
    creator() {
      return this.users.find((user) => user.id === this.annotation.user) || {};
    },
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {boolean} */
    isReview() {
      return this.annotation.type === AnnotationType.REVIEWED;
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    /** @returns {object} */
    visibleTerms() {
      return this.imageWrapper.style.terms.filter((a) => a.visible);
    },
    /** @returns {any} */
    reviewer() {
      if (!this.isReview) return;

      return (
        this.users.find((user) => user.id === this.annotation.reviewUser) || {}
      );
    },
    /** @returns {boolean} */
    canEdit() {
      if (this.isContributor && !this.isAdmin) {
        return (
          this.creator.id === this.currentUser.id &&
          this.$store.getters['currentProject/canEditAnnot'](this.annotation)
        );
      }
      // Checks for superadmin or admin, and user owner of annot
      return this.$store.getters['currentProject/canEditAnnot'](
        this.annotation
      );
    },
    isAdmin() {
      return this.currentUser.admin;
    },
    isSuperAdmin() {
      return this.currentUser.adminByNow;
    },
    isRepresentative() {
      return this.$store.getters['currentProject/isRepresentative'];
    },
    isContributor() {
      return this.$store.getters['currentProject/isContributor'];
    },
    canDelete() {
      return this.canEdit && this.isToolDisplayed('delete');
    },
    /** @returns {boolean} */
    canEditTerms() {
      // HACK: because core prevents from modifying term of algo annot (https://github.com/cytomine/Cytomine-core/issues/1138 & 1139)
      // + term modification forbidden for reviewed annotation
      if (this.isContributor) {
        return (
          this.creator.id === this.currentUser.id &&
          this.canEdit &&
          this.annotation.type === AnnotationType.USER
        );
      }
      return this.canEdit && this.annotation.type === AnnotationType.USER;
    },
    /** @returns {object} */
    image() {
      return (
        this.images.find((image) => image.id === this.annotation.image) || {}
      );
    },
    /** @returns {string} */
    annotationURL() {
      return `/project/${this.annotation.project}/image/${this.annotation.image}/annotation/${this.annotation.id}`;
    },
    /** @returns {object[]} */
    associatedTerms() {
      if (this.annotation.userByTerm) {
        return this.annotation.userByTerm.map((ubt) => {
          const term = this.terms.find((term) => ubt.term === term.id);
          const user = this.users.find((user) => user.id === ubt.user[0]) || {}; // QUESTION: can we have several users?
          return {
            term,
            user,
          };
        });
      } else {
        return [];
      }
    },
    /** @returns {number[]} */
    associatedTermsIds() {
      this.revTerms;
      return this.associatedTerms.map(({ term }) => term.id);
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    imageWrapper() {
      return this.viewerWrapper.images[this.index];
    },
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    activeTool() {
      return this.imageWrapper.draw.activeTool;
    },
    layers() {
      return this.imageWrapper.layers.selectedLayers || [];
    },
    noActiveLayer() {
      return !this.layers.find((layer) => layer.drawOn);
    },
  },
  async created() {
    if (
      this.isPropDisplayed('comments') &&
      [AnnotationType.ALGO, AnnotationType.USER].includes(this.annotation.type)
    ) {
      try {
        this.comments = (
          await AnnotationCommentCollection.fetchAll({
            annotation: this.annotation,
          })
        ).array;
        if (this.showComments) {
          this.openCommentsModal();
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-fetch-annotation-comments'),
        });
      }
    }
  },
  mounted() {
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  },
  methods: {
    isToolDisplayed(tool) {
      return this.isAdmin || this.configUI[`project-tools-${tool}`];
    },
    async iterateTerm(index) {
      const terms = this.terms;
      if (index < 0) {
        const lastTerm = terms[terms.length - 1];
        this.addTerm(lastTerm.id);
      } else if (index >= terms.length) {
        this.addTerm(terms[0].id);
      } else {
        this.addTerm(terms[index].id);
      }
      this.$notify({
        type: 'success',
        text: this.$t('term-changes-success'),
      });
    },

    async shortkeyHandler(key) {
      if (!this.isActiveImage) {
        // shortkey should only be applied to active map
        return;
      }
      if (!['tool-switch-terms-next', 'tool-switch-terms-prev'].includes(key)) {
        return;
      }
      if (this.noActiveLayer || this.activeTool !== 'select') {
        return;
      } else if (this.terms?.length == 0) {
        // Error message no terms available
        this.$notify({
          type: 'error',
          text: this.$t('terms-not-available'),
        });
      } else {
        const annot = this.annotation;
        const lastAssociatedTermId = this.associatedTermsIds[
          this.associatedTermsIds.length - 1
        ];
        // Get index of last associated term
        const termIndex = this.terms.findIndex(
          (term) => term.id === lastAssociatedTermId
        );
        let index = termIndex + 1;
        if (key === 'tool-switch-terms-prev') {
          index = termIndex - 1;
        }
        annot.term.forEach((termId) => this.removeTerm(termId));
        this.iterateTerm(index);
      }
    },
    isPropDisplayed(prop) {
      return (
        this.isAdmin || this.configUI[`project-explore-annotation-${prop}`]
      );
    },

    copyURL() {
      copyToClipboard(window.location.origin + '/#' + this.annotationURL);
      this.$notify({
        type: 'success',
        text: this.$t('notif-success-annot-URL-copied'),
      });
    },

    async newTerm(term) {
      // a new term was added to the ontology
      this.$emit('addTerm', term);
      this.$store.dispatch('currentProject/fetchOntology');
      this.addTerm(term.id);
    },

    async addTerm(idTerm) {
      if (idTerm) {
        try {
          // TODO: fix issue with AlgoAnnotation https://github.com/cytomine/Cytomine-core/issues/1139
          await new AnnotationTerm({
            annotation: this.annotation.id,
            term: idTerm,
          }).save();
          this.$emit('updateTerms');
          this.showTermSelector = false;
        } catch (error) {
          this.$notify({
            type: 'error',
            text: this.$t('notif-error-add-term'),
          });
          this.revTerms++;
        } finally {
          this.addTermString = '';
        }
      }
    },
    /**
     * @param {number} termId
     */
    async removeTerm(termId) {
      const annotation = this.annotation;

      try {
        // TODO fix issue with AlgoAnnotationTerm: https://github.com/cytomine/Cytomine-core/issues/1138
        await noteApi.delete(`/napi/annotation/${annotation.id}/term`, {
          json: {
            projectId: annotation.project,
            termIds: [termId],
          },
        });
        this.$emit('updateTerms');
      } catch (error) {
        const errorText = error?.message
          ? this.$t(error.message)
          : this.$t('notif-error-remove-term');
        this.$notify({
          type: 'error',
          text: errorText,
        });
        this.revTerms++;
      }
    },
    findUserByTerm(idTerm) {
      if (this.annotation.userByTerm) {
        const match = this.annotation.userByTerm.find(
          (ubt) => ubt.term === idTerm
        );
        if (match) {
          return match.user[0];
        }
      }
    },

    openCommentsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: () => import('./AnnotationCommentsModal.vue'),
        props: {
          annotation: this.annotation,
          comments: this.comments,
        },
        hasModalCard: true,
        events: { addComment: this.addComment },
      });
    },

    addComment(comment) {
      this.comments.unshift(comment);
    },

    confirmDeletion() {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-annotation'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteAnnot(),
      });
    },

    async deleteAnnot() {
      try {
        await Annotation.delete(this.annotation.id);
        this.$emit('deletion');
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-deletion'),
        });
      }
    },

    confirmCut() {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-cut-annotations'),
        message: this.$t('confirm-cut-annotations-message'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.cutAnnotations(),
      });
    },

    async cutAnnotations() {
      try {
        this.isLoading = true;
        await reshapeAnnotations({
          imageId: this.annotation.image,
          projectId: this.annotation.project,
          annotationId: this.annotation.id,
          termIds:
            this.imageWrapper.style.terms.length === this.visibleTerms.length
              ? null
              : this.visibleTerms.map((a) => a.id),
          userIds: this.canManageProject
            ? this.layers.filter((l) => l.visible).map((l) => l.id)
            : [this.currentUser.id],
        });
        this.$eventBus.$emit('reloadAnnotations');
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-annotation-cut'),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-annotation-cut'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.table th,
.table td {
  vertical-align: middle;
}

.ontology-tree-container {
  max-height: 30vh;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

>>> .sl-vue-tree-node-item {
  font-size: 0.9em;
}
</style>
