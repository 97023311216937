<template>
  <vl-layer-heatmap>
    <vl-source-vector
      ref="olSource"
      :loader-factory="loaderFactory"
      :strategy-factory="strategyFactory"
      url="-"
    />
  </vl-layer-heatmap>
</template>

<script>
import WKT from 'ol/format/WKT';
import { AnnotationCollection } from 'cytomine-client';
import { annotBelongsToLayer } from '@/utils/annotation-utils';

export default {
  name: 'AnnotationLayer',
  props: {
    index: String,
    layer: Object,
  },
  data() {
    return {
      format: new WKT(),

      resolution: null,
      lastExtent: null,
      clustered: null,
      maxResolutionNoClusters: null,
      hasLoaded: false,
      refreshTimeout: null,
    };
  },
  computed: {
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[
        this.index
      ];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    annotsIdsToSelect() {
      return this.imageWrapper.selectedFeatures.annotsToSelect.map(
        (annot) => annot.id
      );
    },
    imageExtent() {
      return [0, 0, this.image.width, this.image.height];
    },
    selectedFeatures() {
      return this.imageWrapper.selectedFeatures.selectedFeatures;
    },
    ongoingEdit() {
      return this.imageWrapper.draw.ongoingEdit;
    },
    terms() {
      return this.imageWrapper.style.terms || [];
    },
    selectedTermsIds() {
      return this.terms.filter((term) => term.visible).map((term) => term.id);
    },
    activeMirroring() {
      return this.imageWrapper.draw.activeMirroring;
    },
    reviewMode() {
      return this.imageWrapper.review.reviewMode;
    },
  },
  watch: {
    reviewMode() {
      // in review mode, reviewed annotation no longer displayed => need to force reload
      this.clearFeatures();
    },
    selectedTermsIds() {
      this.$emit('isUpdatingAnnotations', true);
      this.reloadAnnotationsHandler();
    },
  },
  mounted() {
    this.$eventBus.$on('addAnnotations', this.addAnnotationsHandler);
    this.$eventBus.$on('selectAnnotation', this.selectAnnotationHandler);
    this.$eventBus.$on('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$on('reviewAnnotation', this.reviewAnnotationHandler);
    this.$eventBus.$on('editAnnotations', this.editAnnotationsHandler);
    this.$eventBus.$on('deleteAnnotations', this.deleteAnnotationsHandler);
    this.$eventBus.$on('mirrorAnnotation', this.mirroringHandler);
  },
  beforeDestroy() {
    // unsubscribe from all events
    this.$eventBus.$off('addAnnotations', this.addAnnotationsHandler);
    this.$eventBus.$off('selectAnnotation', this.selectAnnotationHandler);
    this.$eventBus.$off('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$off('reviewAnnotation', this.reviewAnnotationHandler);
    this.$eventBus.$off('editAnnotations', this.editAnnotationsHandler);
    this.$eventBus.$off('deleteAnnotations', this.deleteAnnotationsHandler);
    this.$eventBus.$off('mirrorAnnotation', this.mirroringHandler);
  },
  methods: {
    clearFeatures() {
      if (this.$refs.olSource) {
        this.$store.commit(
          this.imageModule + 'removeLayerFromSelectedFeatures',
          {
            layer: this.layer,
            cache: true,
          }
        );
        this.$refs.olSource.clearFeatures();
      }
    },

    annotBelongsToLayer(annot) {
      return annotBelongsToLayer(annot, this.layer, this.image);
    },

    addAnnotationsHandler(annots) {
      if (this.$refs.olSource) {
        const currentLayerAnnots = annots.filter((annot) =>
          this.annotBelongsToLayer(annot)
        );
        const featuresToAdd = currentLayerAnnots.map((annot) =>
          this.createFeature(annot)
        );
        this.$refs.olSource.addFeatures(featuresToAdd);
      }
    },
    selectAnnotationHandler({ annot, index }) {
      if (
        index === this.index &&
        this.annotBelongsToLayer(annot) &&
        this.$refs.olSource
      ) {
        const olFeature = this.$refs.olSource.getFeatureById(annot.id);
        if (!olFeature) {
          this.$store.commit(this.imageModule + 'setAnnotToSelect', annot);
        } else {
          this.$store.dispatch(this.imageModule + 'selectFeature', olFeature);
        }
      }
    },
    async reloadAnnotationsHandler({
      idImage,
      clear = false,
      callback = null,
    } = {}) {
      if (!idImage || idImage === this.image.id) {
        if (clear) {
          this.clearFeatures();
        } else {
          await this.loader();
          this.$emit('isUpdatingAnnotations', false);
        }
      }
      if (callback) {
        callback();
      }
    },
    reviewAnnotationHandler(annot) {
      if (this.reviewMode) {
        // if the image is in review mode, reviewed annotation should no longer be displayed on user layer => call delete handler
        this.deleteAnnotationsHandler(annot);
      }
    },
    editAnnotationsHandler(annots, updateSelectedFeatures = true) {
      annots.forEach((annot) => {
        if (this.annotBelongsToLayer(annot) && this.$refs.olSource) {
          const olFeature = this.$refs.olSource.getFeatureById(annot.id);
          if (!olFeature) {
            return;
          }
          olFeature.setGeometry(this.format.readGeometry(annot.location));
          olFeature.set('annot', annot);

          if (updateSelectedFeatures) {
            const indexSelectedFeature = this.selectedFeatures.findIndex(
              (ftr) => ftr.id === annot.id
            );
            if (indexSelectedFeature >= 0) {
              this.$store.commit(
                this.imageModule + 'changeAnnotSelectedFeature',
                {
                  indexFeature: indexSelectedFeature,
                  annot,
                }
              );
            }
          }
        }
      });
    },
    deleteAnnotationsHandler(annots) {
      let clearSelectedFeatures = false;
      annots.forEach((annot) => {
        if (this.annotBelongsToLayer(annot) && this.$refs.olSource) {
          const olFeature = this.$refs.olSource.getFeatureById(annot.id);

          if (!olFeature) return;

          this.$refs.olSource.removeFeature(olFeature);

          if (this.selectedFeatures.some((ftr) => ftr.id === annot.id)) {
            clearSelectedFeatures = true;
          }
        }
      });

      if (clearSelectedFeatures) {
        this.$store.commit(this.imageModule + 'clearSelectedFeatures');
      }
    },
    mirroringHandler() {
      this.clearFeatures();
    },

    strategyFactory() {
      return (extent, resolution) => {
        const extentHasChanged =
          !this.lastExtent ||
          extent[0] !== this.lastExtent[0] ||
          extent[1] !== this.lastExtent[1] ||
          extent[2] !== this.lastExtent[2] ||
          extent[3] !== this.lastExtent[3];
        this.lastExtent = extent;

        if (
          this.$refs.olSource &&
          this.resolution &&
          this.clustered != null && // some features have already been loaded
          ((!this.clustered && resolution > this.maxResolutionNoClusters) || // recluster
            (resolution !== this.resolution && this.clustered))
        ) {
          // change of resolution while clustering
          // clear loaded extents to force reloading features
          this.$refs.olSource.$source.loadedExtentsRtree_.clear();
        }

        if (extentHasChanged) {
          if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
          }
          this.$emit('isUpdatingAnnotations', true);

          this.refreshTimeout = setTimeout(() => {
            this.reloadAnnotationsHandler();
          }, 1000);
        }
        return [extent];
      };
    },

    async fetchAnnots(extent) {
      [0, 1].forEach((index) => {
        if (extent[index] < 0) {
          extent[index] = 0;
        }
      });
      [2, 3].forEach((index) => {
        if (this.imageExtent[index] < extent[index]) {
          extent[index] = this.imageExtent[index];
        }
      });

      if (this.activeMirroring == 'horizontal') {
        var tmp = extent[0];
        extent[0] = extent[2];
        extent[2] = tmp;
        extent[0] = this.imageExtent[0] - extent[0];
        extent[2] = this.imageExtent[2] - extent[2];
      }
      if (this.activeMirroring == 'vertical') {
        var tmp = extent[1];
        extent[1] = extent[3];
        extent[3] = tmp;
        extent[1] = this.imageExtent[1] - extent[1];
        extent[3] = this.imageExtent[3] - extent[3];
      }

      const annots = await new AnnotationCollection({
        user: !this.layer.isReview ? this.layer.id : null,
        image: this.image.id,
        reviewed: this.layer.isReview,
        notReviewedOnly: !this.layer.isReview && this.reviewMode,
        bbox: extent.join(),
        showWKT: true,
        showTerm: true,
        showGIS: true,
        kmeans: true,
      }).fetchAll();

      let annotsArray = annots.array;
      // filter all annotations that contain a term
      if (this.selectedTermsIds.length !== this.terms.length) {
        annotsArray = annots?.array.filter(
          (annot) =>
            !annot.term ||
            annot.term.length === 0 ||
            annot.term.some((termId) => this.selectedTermsIds.includes(termId))
        );
      }

      return annotsArray;
    },

    updateFeature(feature, annot) {
      const indexSelectedFeature = this.selectedFeatures.findIndex(
        (ftr) => ftr.id === feature.getId()
      );
      const isFeatureSelected = indexSelectedFeature !== -1;

      if (!annot) {
        console.log(
          `Removing annot ${feature.getId()} in layer ${
            this.layer.id
          } (external action)`
        );
        this.$refs.olSource.removeFeature(feature);
        if (isFeatureSelected) {
          this.$store.commit(this.imageModule + 'clearSelectedFeatures');
        }
        return;
      }

      const storedAnnot = feature.get('annot');
      const numStoredAnnotIndicies = storedAnnot.location.split(',').length;
      const numAnnotIndicies = annot.location.split(',').length;

      if (
        !this.clustered &&
        annot.updated === storedAnnot.updated &&
        numStoredAnnotIndicies === numAnnotIndicies &&
        this.sameTerms(annot.term, storedAnnot.term)
      ) {
        // no modification performed since feature was loaded
        return;
      }

      if (isFeatureSelected) {
        if (this.ongoingEdit) {
          // if feature is selected and under modification, updating it may lead to conflict
          console.log(
            `Skipping update of selected annot ${annot.id} in layer ${this.layer.id} (ongoing edit)`
          );
          return;
        }
        console.log(
          `Updating selected annot ${annot.id} in layer ${this.layer.id} (external action)`
        );
        this.$store.commit(this.imageModule + 'changeAnnotSelectedFeature', {
          indexFeature: indexSelectedFeature,
          annot,
        });
      }

      console.log(
        `Updating annot ${annot.id} in layer ${this.layer.id} (external action)`
      );
      feature.set('annot', annot);
      feature.setGeometry(this.format.readGeometry(annot.location));
    },

    async loader(extent = [...this.lastExtent], resolution = this.resolution) {
      this.resolution = resolution;

      if (!this.layer.visible || !extent) {
        return;
      }

      let arrayAnnots;
      try {
        arrayAnnots = await this.fetchAnnots(extent);
        // Order by size, so bigger ones are always sent to back
        arrayAnnots.sort(function(a, b) {
          if (a.area < b.area) return 1;
          else return -1;
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-fetch-annotations-viewer'),
        });
        return;
      }

      if (!this.$refs.olSource) {
        return;
      }

      const wasClustered = this.clustered;
      if (arrayAnnots.length) {
        this.clustered = arrayAnnots[0].count != null;
        if (!this.clustered && resolution > this.maxResolutionNoClusters) {
          this.maxResolutionNoClusters = resolution;
        }
      }

      // Re-enable if annotations are ever updated individually again
      // this was disabled when annotations were cleared and re-added on every fetch
      // this was to prevent too many annotations rendered at once which causes JS heap overload

      // const annots = arrayAnnots.reduce((obj, annot) => {
      //   obj[annot.id] = annot;
      //   return obj;
      // }, {});
      const seenAnnots = [];

      if (wasClustered !== null && wasClustered !== this.clustered) {
        this.clearFeatures(); // clearing features will retrigger the loader
      } else {
        // const features = this.clustered
        //   ? this.$refs.olSource.$source.getFeatures()
        //   : this.$refs.olSource.$source.getFeaturesInExtent(extent);
        // features.forEach((feature) => {
        //   this.updateFeature(feature, annots[feature.getId()]);
        //   seenAnnots.push(feature.getId());
        // });
      }

      const { activeMirroring, image } = this;
      const annotsToAdd = [];
      arrayAnnots.forEach((annot) => {
        if (seenAnnots.includes(annot.id)) return;
        if (activeMirroring === 'horizontal') {
          // matches all numbers (including fractions) that are follow by a whitespace character
          // the string being processed is a WKT string, so this amounts to finding
          // the x-coordinates of the WKT string
          const regex = /[\d\.]+\s/g;
          const result = annot.location.replaceAll(regex, (match) => {
            const lastChar = match.substring(match.length - 1);
            const mappedCoord =
              image.width - Number(match.substring(0, match.length - 1));
            return String(mappedCoord) + lastChar;
          });
          annot.location = result;
        }
        if (activeMirroring === 'vertical') {
          // matches all numbers (including fractions) that are follow by right parenthesis or a comma symbol
          // the string being processed is a WKT string, so this amounts to finding
          // the y-coordinates of the WKT string
          const regex = /[\d\.]+[\)\,]/g;
          const result = annot.location.replaceAll(regex, (match) => {
            const lastChar = match.substring(match.length - 1);
            const mappedCoord =
              image.height - Number(match.substring(0, match.length - 1));
            return String(mappedCoord) + lastChar;
          });
          annot.location = result;
        }
        annotsToAdd.push(this.createFeature(annot));
      });
      this.$refs.olSource.clearFeatures();
      this.$refs.olSource.addFeatures(annotsToAdd);
    },

    loaderFactory() {
      return (extent, resolution) => {
        // this should be run on initial load only
        // for some reason this will fire multiple times when fully zoomed out and panning/rotating
        // NOTE: for updates when panning/zooming, use strategy factory
        if (!this.hasLoaded) {
          this.loader(extent, resolution);
          this.hasLoaded = true;
        }
      };
    },

    createFeature(annot) {
      annot.location = `POINT(${annot.x} ${annot.y})`; // Convert all annotations to POINTS. VueLayers heatmap only takes points.
      const feature = this.format.readFeature(annot.location);
      feature.setId(annot.id);
      feature.set('annot', annot);

      if (this.annotsIdsToSelect.includes(annot.id)) {
        this.$store.dispatch(this.imageModule + 'selectFeature', feature);
      }

      return feature;
    },

    sameTerms(terms1, terms2) {
      if (terms1.length !== terms2.length) {
        return false;
      }
      return terms1.every((term) => terms2.includes(term));
    },
  },
};
</script>
