<template>
  <div>
    <h2 class="text-center mb-3">
      {{ $t('results') }}
    </h2>
    <div v-if="properties.length === 0" class="text-center color-gray-4">
      <i>{{ $t('results-not-available') }}</i>
    </div>
    <div v-else class="my-3">
      <CytomineProperties
        :object="image"
        :can-edit="canEdit && isRepresentative && isSuperAdmin"
      />
    </div>
    <div v-else class="my-3">
      <CytomineProperties
        :object="image"
        :can-edit="canEdit && isAdminAndProjectRepresentative"
      />
    </div>
    <LaunchV2JobModal
      :active.sync="openV2JobModal"
      :assay-params="v2AssayParams"
      :algorithm-selected="algorithmSelected"
      :selected-img-ids="[image.id]"
      :id-project="project.id"
      :revision.sync="revision"
    />
    <LaunchJobModal
      :active.sync="openJobModal"
      :assay-params="assayParams"
      :algorithm-selected="algorithmSelected"
      :selected-img-ids="[image.id]"
      :id-project="project.id"
      :revision.sync="revision"
    />
  </div>
</template>

<script>
import { PropertyCollection } from 'cytomine-client';
import { LaunchV2JobModal, LaunchJobModal } from '../../image/index.js';
import constants from '@/utils/constants.js';
import CytomineProperties from '@/components/property/CytomineProperties.vue';
import noteApi from '@/services/noteApi';

/**
 * @typedef {{
 * key: string
 * value: string
 * units?: string
 * class?: string
 * min?: number
 * max?: number
 * }} Property
 */

export default {
  name: 'ResultsPanel',
  components: {
    CytomineProperties,
  },
  props: {
    index: { type: String, default: '' },
  },
  data() {
    return {
      /** @type {Property[]} */
      properties: [],
      loading: true,
      error: false,

      //asays
      availableAssays: [],
      algorithmSelected: null,
      openV2JobModal: false,
      v2AssayParams: [],
      openJobModal: false,
      assayParams: [],
      revision: 0,
    };
  },
  computed: {
    /** @returns {object} */
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {object} */
    viewerModule() {
      return this.$store.getters['currentProject/currentViewerModule'];
    },
    /** @returns {object} */
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    /** @returns {object} */
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    image() {
      return this.viewerWrapper.images[this.index].imageInstance;
    },
    /** @returns {boolean} */
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    /** @returns {boolean} */
    canEdit() {
      // checks if user is admin/superadmin
      return this.$store.getters['currentProject/canEditAnnot'](this.image);
    },
    isRepresentative() {
      return this.$store.getters['currentProject/isRepresentative'];
    },
    isSuperAdmin() {
      return this.currentUser.adminByNow;
    },
  },
  async created() {
    try {
      // Get Properties
      const ontologyId = this.ontology?.id;
      const requests = [
        PropertyCollection.fetchAll({
          object: this.image,
        }).then((collection) => collection.array),
      ];
      if (ontologyId) {
        requests.push(noteApi.get(`napi/ontology/${ontologyId}`));
      }
      const [allProperties, ontology] = await Promise.all(requests);

      // filter the properties used internally
      const properties = allProperties.reduce((finalProps, prop) => {
        if (!prop.key.startsWith(constants.PREFIX_HIDDEN_PROPERTY_KEY)) {
          if (this.isNumeric(prop.value)) {
            prop.value = Math.round(prop.value * 100) / 100; // round to 2 decimal point
          }
          finalProps.push(prop);
        }
        return finalProps;
      }, []); // filter the properties used internally

      /** @type {Array<{key: string, min: number, max: number, unit: string, class: string}>} */
      const formatters = ontology?.meta?.format ?? [];

      const formattedResults = [];

      for (const property of properties) {
        const formattedResult = {
          key: property.key,
          value: property.value,
        };
        const matchingFormat = formatters.find((formatter) => {
          return formatter.key === property.key;
        });

        if (matchingFormat) {
          formattedResult.unit = matchingFormat.unit;
          formattedResult.min = Number(matchingFormat.min);
          formattedResult.max = Number(matchingFormat.max);
          formattedResult.class = matchingFormat.class;
        }

        // Casts value to number if valid
        if (Number(formattedResult.value)) {
          formattedResult.value = Number(formattedResult.value);
        }

        // Move results with highlight class to display first
        if (formattedResult.class === 'highlight') {
          formattedResults.unshift(formattedResult);
        } else {
          formattedResults.push(formattedResult);
        }
      }

      this.properties = formattedResults;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    isNumeric(str) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !Number.isNaN(str) && !Number.isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    }, // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
  },
};
</script>

<style scoped>
td {
  word-wrap: break-word;
}
</style>
